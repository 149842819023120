<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <validation-provider rules="required|max:20" v-slot="{ errors }" name="modelName">
            <div class="row-div">
              <div class="item-name-div">挖机型号：</div>
              <div class="item-control-div">
                <label><input type="text" v-model="partsModelInfo.model" maxlength="10" placeholder="请输入挖机型号"></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <validation-provider rules="required|max:200" v-slot="{ errors }" name="partsMemo">
            <div class="row-div">
              <div class="item-name-div">备注说明：</div>
              <div class="item-control-div">
                <label><textarea v-model="partsModelInfo.remarks" maxlength="200" placeholder="请输入备注说明"/></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <template v-if="partsRules.length > 0">
          <div class="item-div">
            <div class="row-div">
              <div class="item-name-div">配件属性：</div>
              <div class="operate-attribute-div">
                <label><img class="cursor-el" src="../../assets/add-circle.png" alt="添加" @click="onAddAttribute"/></label>
                <label><img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute"/></label>
              </div>
            </div>
          </div>

          <div class="parts-attribute-div">
            <div class="item-header-div">
              <label>分类</label>
              <label v-for="item in partsRules">{{ item }}</label>
            </div>
            <div class="parts-attribute-item-div" v-for="item in partsModelInfo.attributes">
              <label><input v-model="item.sort" type="text" maxlength="100" placeholder="请输入分类"></label>
              <label><input v-model="item.name" type="text"></label>
              <label><input v-model="item.value" type="text"></label>
              <label><input v-model="item.memo" type="text"></label>
            </div>
          </div>
        </template>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { postBrandPartsModelAdd } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    partsId: {
      type: String,
      default: ""
    },
    rules: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      partsModelInfo: {
        model: "",
        remarks: "",
        attributes: []
      },
      partsRules: [],
      tipMsg: ""
    }
  },
  created() {
    this.partsRules = this.rules.split(",");
  },
  methods: {
    onAddAttribute() {
      this.partsModelInfo.attributes.push({
        sort: "",
        name: "",
        value: "",
        memo: ""
      })
    },
    onMinusAttribute() {
      this.partsModelInfo.attributes.pop();
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      let formData = new FormData();
      formData.append("partsId", this.partsId);
      formData.append("model", this.partsModelInfo.model);
      formData.append("remarks", this.partsModelInfo.remarks);
      if(this.partsRules.length > 0){
        this.partsModelInfo.attributes.forEach((v, i) => {
          formData.append(`attributes[${i}].sort`, v.sort);
          formData.append(`attributes[${i}].name`, v.name);
          formData.append(`attributes[${i}].value`, v.value);
          formData.append(`attributes[${i}].memo`, v.memo);
        });
      }

      this.$refs.form.validate().then(res => {
        if(res){
          postBrandPartsModelAdd(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 500px;
    margin: 20px auto 0 auto;
    .item-div {
      margin-top: 5px;
      .row-div {
        display: flex;
        align-items: center;
        .item-name-div {
          width: 70px;
          text-align: right;
          margin-right: 15px;
        }
        .item-control-div {
          .brand-select {
            width: 230px;
            height: 33px;
          }
          .group-select {
            width: 150px;
            height: 33px;
          }
          .group-select:first-child {
            margin-right: 10px;
          }
          input {
            width: 358px;
            height: 33px;
            padding: 0 5px;
            border: 1px solid #dcdcdc;
          }
          textarea {
            width: 292px;
            height: 80px;
            resize: none;
            padding: 3px;
            border: 1px solid #dcdcdc;
          }
        }
        .group-select-div {
          display: flex;
        }
        .parts-img-div {
          display: flex;
          .preview-img-div {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 10px;
            .del-img {
              width: 16px;
              height: 16px;
              position: absolute;
              top: 0;
              right: 0;
            }
            .preview-img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              align-items: center;
            }
          }
        }
        .operate-attribute-div {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .item-header-div {
          margin-top: 5px;
          display: flex;
          label {
            padding: 5px;
            border-top: 1px solid #dcdcdc;
            border-right: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
          }
          label:first-child {
            border-left: 1px solid #dcdcdc;
          }
        }
      }
      .tips-div {
        width: calc(100% - 90px);
        height: 25px;
        margin-left: 90px;
      }
    }
    .parts-attribute-div {
      margin-top: 5px;
      margin-left: 90px;
      .item-header-div {
        display: flex;
        margin-top: 5px;
        label {
          width: 110px;
          height: 25px;
          text-align: center;
        }
      }
      .parts-attribute-item-div {
        display: flex;
        input {
          width: 102px;
          height: 33px;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>